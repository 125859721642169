import styled, { css } from 'styled-components'

export const StyledBillingTitle = styled.h2`
  margin: 0;
  font-weight: normal;
  font-size: 10px;

  ${(props) => {
    if (props.theme.updatedLayout) {
      const mobile = css`
        font-size: 28px;
        line-height: 36px;
      `
      const tabLandDesk = css`
        font-size: 32px;
        line-height: 40px;
      `
      const responsive = {
        'mobile-container': mobile,
        'tablet-portrait-container': mobile,
        'tablet-landscape-container': tabLandDesk,
        'desktop-container': tabLandDesk
      }
      return responsive[props.theme.containerSize]
    } else {
      const mobile = css`
        font-size: 24px;
        line-height: 32px;
      `
      const tabPort = css`
        font-size: 32px;
        line-height: 40px;
      `
      const tabLandDesk = css`
        font-size: 40px;
        line-height: 48px;
        margin: 6px 0 8px;
      `
      const responsive = {
        'mobile-container': mobile,
        'tablet-portrait-container': tabPort,
        'tablet-landscape-container': tabLandDesk,
        'desktop-container': tabLandDesk
      }
      return responsive[props.theme.containerSize]
    }
  }}

  ${({ theme: { updatedLayout } }) =>
    updatedLayout &&
    css`
      align-self: flex-start;
      margin-bottom: 4px;
    `}
`

import styled, { css } from 'styled-components'

export const StyledPgsForm = styled.iframe`
  width: 100%;
  height: 80px;
  border: none;
`

interface PgsProps {
  show: boolean
}

export const StyledPgsContainer = styled.div<PgsProps>`
  display: flex;
  flex-direction: column;
  align-items: center;

  ${({ theme: { updatedLayout } }) =>
    updatedLayout &&
    css`
      width: 100%;
    `}

  ${(props) =>
    props.show
      ? css``
      : css`
          display: none;
        `}
`
